import React from "react"
import { graphql } from "gatsby"

// Layout
import Layout from "../components/layout"
// Components
import Menu from "../components/home/menu"
import Breadcrumb from "../components/elements/breadcrumb"
import AudioSingle from "../components/elements/audio-single"
// Hooks
import { useAsmetHome } from "../hooks/use-asmet-home"

const AsmetAudioTemplate = ({ data }) => {
  const singleAudio = data.asmetAudio

  const asmetHome = useAsmetHome()

  const crumbs = [
    { path: `/media`, label: "Media" },
    { path: `/audios`, label: "Audios" },
    { path: `/audios/${singleAudio.slug}`, label: singleAudio.titulo },
  ]

  return (
    <Layout
      meta={
        singleAudio.meta
          ? { ...singleAudio.meta, og_tipo: "audio" }
          : {
              og_tipo: "audio",
              og_titulo: singleAudio.titulo,
              og_descripcion: singleAudio.descripcion || null,
            }
      }
    >
      <div id="asm-template-header" className="contanier-fluid pt-3 pb-3">
        <div className="container">
          <div className="row">
            <div className="col">
              <Breadcrumb crumbs={crumbs} />
              <h3 className="text-white-alpha text-left pt-4">{singleAudio.titulo}</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="asm-media-main container my-5 py-5">
        <div className="row justify-content-center">
          <div className="asm-media-single col-12 col-sm-8">
            <AudioSingle singleAudio={singleAudio} />
          </div>
        </div>
      </div>
      <hr />
      <Menu menuSecundario={asmetHome.menu_secundario} />
    </Layout>
  )
}

export default AsmetAudioTemplate

export const query = graphql`
  query AsmetAudioQuery($id: String!) {
    asmetAudio: strapiMediaAudio(strapiId: { eq: $id }) {
      strapiId
      slug
      titulo
      meta {
        og_titulo
        og_imagen {
          publicURL
        }
        og_descripcion
      }
      publicado_en
      descripcion
      archivo {
        publicURL
      }
      tipo
      blog_subcategorias {
        id
        slug
        nombre
      }
    }
  }
`
